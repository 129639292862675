import React, { Component } from "react";

export default class CloudInfraImg extends Component {
  render() {
    return (
      <img
        style={{ width: "70%" }}
        src={require(`../../assests/images/devops.svg`)}
        alt="cloud-infra"
      />
    );
  }
}
