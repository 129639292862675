/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Tayfun's Portfolio",
  description:
    "A software engineer with a solid background in both the technical and collaborative aspects of development. I find great joy in the creative process of software development. My passion is driven by the challenges of turning ideas into reality and the possibility of shaping the future. I'm always eager to leverage my skills in developing software that makes a difference, contributing to a team’s success while pursuing personal and professional growth.",
  og: {
    title: "Tayfun Yurdaer Portfolio",
    type: "website",
    url: "http://tyfun.dev/",
  },
};

//Home Page
const greeting = {
  title: "Tayfun Yurdaer",
  logo_name: "Tayfun Yurdaer",
  nickname: "Software Engineer",
  subTitle:
    "A software engineer with a solid background in both the technical and collaborative aspects of development. I find great joy in the creative process of software development. My passion is driven by the challenges of turning ideas into reality and the possibility of shaping the future. I'm always eager to leverage my skills in developing software that makes a difference, contributing to a team’s success while pursuing personal and professional growth.",
  resumeLink:
    "https://drive.google.com/file/d/1FL_wydnPF2SWCdw2goYAgZ1KKBx8TpcD/view?usp=sharing",
  portfolio_repository: "https://github.com/yt/tayfunPortfolio",
  githubProfile: "https://github.com/yt",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/yt",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/tayfun-yurdaer/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:tayfun@tyfun.dev",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
];

const skills = {
  data: [
    {
      title: "DevOps & SRE",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Leadership in multi-disciplinary teams",
        "⚡ Expertise in safety-critical operations",
        "⚡ Cloud and on-prem platforms experience",
        "⚡ CI/CD pipeline development and maintenance",
        "⚡ Advanced linux proficiency",
        "⚡ Compliance driven development experience",
        "⚡ Environment configuration for diverse teams and fields",
        "⚡ Big Data management in Robotics",
        "⚡ E-commerce platform leadership",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Github actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#333333",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "netdata",
          fontAwesomeClassname: "simple-icons:netdata",
          style: {
            color: "rgb(0, 171, 68)",
          },
        },
        {
          skillName: "ubuntu",
          fontAwesomeClassname: "simple-icons:ubuntu",
          style: {
            color: "#e95420",
          },
        },
        {
          skillName: "grafana",
          fontAwesomeClassname: "simple-icons:grafana",
          style: {
            color: "#e95420",
          },
        },
      ],
    },
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Startup development leadership",
        "⚡ Full Teleoperation development",
        "⚡ Automated Vehicle dashboard development",
        "⚡ Safety-Critical API development",
        "⚡ Rapid React UI prototyping",
        "⚡ Low Latency communication solutions",
        "⚡ Comprehensive Data Application engineering",
        "⚡ Commercial Test Suite development",
      ],
      softwareSkills: [
        {
          skillName: "python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:nodedotjs",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Yarn",
          fontAwesomeClassname: "simple-icons:yarn",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "influxDB",
          fontAwesomeClassname: "simple-icons:influxdb",
          style: {
            color: "#333333",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [],
};

const degrees = {
  degrees: [],
};

const certifications = {
  certifications: [],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "My work",
  description: `I have worked with many evolving startups as a software engineer.
    During my career, I had the opportunity to work on many different projects, great mentors and with many different teams. 
    My experiences with these companies have helped me to grow and develop special skills on how to achieve success on any field.`,
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Techinal Lead",
          company: "ADASTEC Inc.",
          company_url: "https://www.linkedin.com/company/freecopy/about/",
          logo_path: "adastec.ico",
          duration: "Jan 2021 - Present",
          location: "Istanbul, Türkiye",
          description: `Working as Technical Team Lead at ADASTEC. 
            We are developing automated vehicles that are operational all over the world. 
            My work revolves around critical software architectures, DevOps and remote control systems. 
            Developed full observability and monitoring pipelines, remote control center, robotics data processing and storage systems. 
            Enabled safe CI and safe CD pipelines. 
            Developed low latency safe teleoperation system.
            Worked with multi-disciplinary teams, hired and trained new engineers. 
            Proud to be part of the team that is shaping the future.`,
          color: "#fc1f20",
        },
        {
          title: "Software Engineer",
          company: "Snapbytes Inc.",
          company_url: "https://snapbytes.com",
          logo_path: "snapbytes.png",
          duration: "Nov 2019 - Jan 2021",
          location: "Istanbul, Türkiye",
          description: `I was one of the main developers at the product testb4, which offers a complete platform to test mobile and web applications. 
            Lets you write scenarios in a human language and run them on real devices.
            Used Java, PostgreSQL, React.js, and Jenkins to build the product.`,
          color: "#9b1578",
        },
        {
          title: "Software Engineer",
          company: "Smartgift, 1-800-FLOWERS.COM INC.",
          company_url: "https://www.smartgift.com/",
          logo_path: "smartgift.png",
          duration: "Feb 2018 - Nov 2019",
          location: "New York, United States",
          description: `I worked on the development of the Smartgift SaaS e-commerce platform. 
          I built the automated e2e testing platform with Puppeteer and wrote internal applications with React and Sails.js. 
          During my time in New York, I have acted as a business analyst to integrate the solutions with the clients. 
          Later, the company is sold to 1-800-Flowers.com Inc.`,
          color: "#0879bf",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "stic.webp",
    description:
      "I am available for any questions. You can message me, I will reply within 24 hours. I can help you with System Design, Cloud and Full Stack Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I recently started blogging my experiences. You can read my blogs here.",
    link: "https://dev.to/tayfun",
    avatar_image_path: "blogs_image.svg",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
